import Vue from 'vue'
import VueRouter from 'vue-router'
import loginView from '@/views/login/index.vue'
import util from '@/utils/util'
import versionTood from '@/libs/versionUpdate'
import Cookies from 'js-cookie'

Vue.use(VueRouter)


const routes = [
  {
    path: '/',
    name: 'home',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'login',
    component: loginView
  },
  {
    path: '/selectionProject',
    name: 'selectionProject',
    component: () => (import('@/views/selectionProject/index.vue'))
  },
  {
    path:'/temporarily/closed',
    component: ()=> (import('@/views/temporarilyClosed/index.vue'))
  },
  {
    path: '/scoreLogin',
    name: 'scoreLogin',
    component: () => (import('@/views/scoreLogin/index'))
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '@/views/HomeView.vue')
  },
  {
    path: '/finishInfo',
    name: 'finishInfo',
    component:() =>import('@/views/finish/index.vue')
  },
  {
    path: '/organizationList',
    name: 'selectOrganization',
    component:() =>import('@/views/selectOrganization/index.vue')
  },
  { path: '/404',name:'404', component: () => import('@/views/errorPage/404.vue')}
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to,from,next)=>{
  //判断当前代码版本是否与服务器中代码版本一致，如不一致则刷新页面获取最新
  versionTood.isNewVersion();
  let itemByQuery = Cookies.get('itemByQuery'),
      strH = [271,150];
      itemByQuery = itemByQuery?JSON.parse(itemByQuery):{}

  if(util.isSystemStatus() && !strH.includes(itemByQuery.id) && to.path.indexOf('temporarily/closed') === -1){
    next('/temporarily/closed')
    return;
  }else{
    next();
  }
})

export default router
