import axios from 'utils/ajax';
import Global from "@/utils/global";

//登录接口
export const userLogin = (params)=>{
    return axios.ajax4UserMgt(Global.getBaseUrl() + '/user/login','post',params,true)
}

//判断当前是否允许获取验证码
export const getPhoneUserStatus = (params)=>{
    return axios.ajax4UserMgt(Global.getBaseUrl() + '/userBasic/getPhoneUserStatus','get',params)
}

//超管切换项目
export const enterProject = (params)=>{
    return axios.ajax4UserMgt(Global.getBaseUrl() + '/user/enterProject','post',params)
}

//获取当前项目域名对应的资源
export const getItemByQuery = (params)=>{
    return axios.ajax4UserMgt( process.env.VUE_APP_BASE_URL + '/organizationapi/organization/getItemByQuery','get',params,true);
}

//如果token已存在，直接获取登录信息跳转对应的页面
export const getLoginInfo = ()=>{
    return axios.ajax4UserMgt(Global.getBaseUrl() + '/user/getLoginInfo','get','',true, {needToken:true, noExit: true});
}
//发送短信验证码
export const sendPhoneVerificationCode = (params)=>{
  return axios.ajax4UserMgt(Global.getParamsKey('BASES_URL') + '/message/sendPhoneVerificationCode','post',params,true, {needToken:false, noExit: true});
}

//隐私协议
export const queryTheLatestData = (params)=>{
    return axios.ajax4UserMgt(Global.getParamsKey('BASES_URL') + '/privacyStatement/queryTheLatestData','get',params,true)
}

//获取用户协议
export const getUserAgreementTheLatestData = (params)=>{
    return axios.ajax4UserMgt(Global.getParamsKey('BASES_URL') + '/privacyStatement/getUserAgreementTheLatestData','get',params,true)
}
//通过用户id获取评审人员还是成员
export const getMemberOrManagerByUserId = (params)=>{
  return axios.ajax4UserMgt(Global.getParamsKey('COP') + '/themeUser/getMemberOrManagerByUserId','post',params,true, {needToken:true, noExit: true});
}
// 获取我的项目菜单权限
export const getMyProjectPermissionOrganizationDto = (params) => {
    return axios.ajax4UserMgt(Global.getParamsKey('userapi') +'/permissionOrganization/getMyProjectPermissionOrganizationDto','get',params, true, {
        needToken: true});
}